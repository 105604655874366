<template>
  <base-section id="FleetopFeatures">
      <base-section-heading title="KEY Features"></base-section-heading>

      <!--v-container>
      <v-row>
        <v-col v-for="(feature, i) in features" :key="i" cols="12" md="6">
          <base-avatar-card v-bind="feature" align="left" horizontal>feature.Description</base-avatar-card>
        </v-col>
      </v-row>
      </v-container-->
      <v-container class="text-center" fill-height>
        <v-row>
          <v-col v-for="({ icon, title, Description }, i) in features" :key="i" cols="12" md="6">
            <v-card class="py-12 px-4" outlined color="transparent">
              <v-theme-provider dark>
                <div>
                  <v-avatar color="primary" size="88">
                    <v-icon large v-text="icon"></v-icon>
                  </v-avatar>
                </div>
              </v-theme-provider>

              <v-card-title class="justify-center font-weight-black text-uppercase" v-text="title"></v-card-title>

              <v-card-text class="subtitle-1" v-text="Description"></v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

  </base-section>
</template>

<script>
export default {
  name: "FleetopFeatures",

  computed: {
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? "100vh" : "50vh";

      return `calc(${height} - ${this.$vuetify.application.top}px)`;
    }
  },

  data: () => ({
    features: [
      {
        title: "Save Fuel",
        icon: "mdi-gas-station",
        Description:
          "Automatic Fuel Refill/Theft Alert System for all kinds of vehicles like Tanker , Rig , RMC , Logistics vehicles, JCBs, Mining Machinaries, DG Sets,Buses, School/College Vans etc.."
      },
      {
        title: "Notifications on Mobile",
        icon: "mdi-cellphone",
        Description:
          "Identify anomalies and Diesel theft with the help of Android/iOS App Notifications on Mobile along with vehicle halts, over speed & vehicle trip details"
      },
      {
        color: "primary",
        dark: true,
        title: "Increase in Mileage",
        icon: "mdi-water",
        Description:
          "Diesel Pilferage control using On Time Fuel Theft/Refill Alarms, Track driving habits and enhance diesel efficiency of vehicles "
      },
      {
        title: "Relaibility",
        icon: "mdi-image-size-select-actual",
        Description:
          "Our Digital Fuel Level Sensors are designed for precision fuel level measurement in all kinds of vehicle's tanks and including old vehicles, machinaries"
      },
      {
        title: "Live Tracking",
        icon: "mdi-map-marker",
        Description:
          "Owners can track the vehicle remotely through WEB based or Android mobile/iOS mobile applications"
      },
      {
        title: "Automate Daily Jobs",
        icon: "mdi-fountain-pen-tip",
        Description:
          "Reminders can be set for repetative mantainance jobs like Service Due, Insurance renewal , Oil filter change etc.."
      }
    ]
  })
};
</script>
